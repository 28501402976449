@import "standard";

/* styles.css */

html,
body {
  height: 100%;
}

body {
  font-family: "proxima-nova", sans-serif;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  color: gray;
}

img {
  max-width: 100%;
}
.h1,
h1 {
  font-size: 52px;
  font-weight: 700;
  line-height: 64px;
  margin: 0 0 48px;
}
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  color: #142149;
  font-weight: 300;
  margin-bottom: 0;
}

/* Nav */
nav {
  z-index: 5;
  li {
    margin-left: 25px;
  }
}
.navbar {
  min-height: 80px;
  .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
  }
  .navbar-toggler {
    border-color: rgb(255, 255, 255);
  }
}
.navbar a,
.navbar .navbar-toggler {
  color: white;
}

.scroll-up {
  position: fixed;
  background: #fff;
  animation-duration: 0.3s;
  animation-fill-mode: both;
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
  box-shadow: 0 4px 32px 0 rgba(10, 14, 29, 0.02), 0 8px 64px 0 rgba(10, 14, 29, 0.08);
  min-height: 80px;
  a {
    color: black;
  }
  .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
  }
  .navbar-toggler {
    border-color: rgb(0, 0, 0);
  }
}

/* Banner */
.banner_desc {
  margin-bottom: 0;
  font-size: 24px;
  font-weight: 300;
  line-height: 40px;
  color: rgba(229, 235, 255, 0.8);
  text-align: center;
  font-family: Roboto, sans-serif;
}

.banner_title {
  z-index: 21;
  position: relative;
}
.banner_title > h1 {
  color: white;
  font-weight: bold;
}
.products {
  z-index: 10;
  position: relative;
  .product {
    margin-bottom: 1rem;
  }
}
.banner {
  position: relative;
  z-index: 1;
  padding: 176px 0 120px;
}
.banner--illustration,
.banner_background {
//   min-height: 942px;
  min-height: 925px;
  width: 100%;
}
.banner_background {
  position: absolute;
  top: 0;
  left: 50%;
  z-index: 10;
  height: 100%;
  //   min-width: 1900px;
  width: 100%;
  //   user-select: none;
  transform: translateX(-50%);
  background: linear-gradient(90deg, #021048, #1e38a3);
}
.banner_background > svg {
  position: absolute;
  bottom: -1px;
  left: 0px;
  // width: 100px;
  float: left;
}
.svg-banner-shape {
  fill: #fff;
}
.site-banner {
  margin-bottom: -450px;
}

/* stripe */
.stripe {
  padding: 3rem 0;
}

.stripe-dark {
  background: #f3f9f3;
}

.stripe-caption {
  text-align: center;
}

.stripe .card-text {
  text-align: center;
}

// Features
.valpro .container {
  border-bottom: 1px solid #edeef2;
}
.feature-content {
  padding: 4rem 0;
  //   overflow: hidden;
}

// .feature-content img {
//   margin-bottom: -8rem;
// }

.feature-dark {
  background: #262f36;
  color: white;
}

.feature-dark h2 {
  color: white;
}

.feature-alt {
  background: #f0f0f0;
}

#feature-five img {
  margin-top: 2rem;
}
// Stripe

/* stripe */
.stripe {
  padding: 3rem 0;
}

.stripe-dark {
  background: #f3f9f3;
}

.stripe-caption {
  text-align: center;
}

.stripe .card-text {
  text-align: center;
}
/* why */
#why .row {
  padding: 1rem 0;
}
#why h3 {
  padding: 1rem 0;
}

/* About */
.about {
  background: url("../img/chalkboard.jpg") center center no-repeat;
  background-size: cover;
}

.about-text {
  background: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 1.875rem;
  h3,
  h5 {
    color: white;
  }
  p {
    color: rgba(229, 235, 255, 0.56);
  }
}

/* footer */
.about h3,
footer h6,
.company-detail {
  color: white;
}

.company-detail {
  letter-spacing: 1px;
}

.footer {
  background: linear-gradient(90deg, #142149, #233ba2);
  overflow: hidden;
  font-size: 15px;
  line-height: 24px;
  color: rgba(229, 235, 255, 0.56);
}
.footer > .container {
  padding: 2rem 3rem;
}

.footer li,
.footer h6 {
  padding: 6px 0;
  white-space: normal;
  transition: 0.3s ease;
  a {
    color: rgba(229, 235, 255, 0.56);
  }
}

.footer li a:hover {
  padding: 6px 0;
  white-space: normal;
  transition: 0.3s ease;
  color: white;
  text-decoration: none;
}

.page-desc {
  margin: -48px auto 72px;
  font-size: 1.1rem;
  // font-weight: 300;
  line-height: 2rem;
  // padding: 4rem;
}
.page {
  z-index: 10;
  position: relative;
//   margin-top: -35px;
}

@media (min-width: 1232px) {
  .page {
    max-width: 1020px;
  }
}
